<template>
<Layout style="background-color:white;">
    <PageHeader :title="title" :items="items" />
        <div class="container-fluid">
            <b-row>
                <b-col cols="6">

                    <b-card title="Dist Oluştur">
                    <b-card-text>
                            <b-form-group
                                id="input-group-1"
                                label="Dist Adı:"
                                label-for="input-1"
                            >
                                <b-form-input
                                id="input-1"
                                v-model="distadi"
                                type="text"
                                placeholder="Dist Adı"
                                required
                                ></b-form-input>
                            </b-form-group>

                            <b-button type="submit" variant="primary" @click="onSubmit">Kaydet</b-button>
                       
                    </b-card-text>
                </b-card>

                </b-col>
                <b-col cols="6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    Show&nbsp;
                                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                    Ara:
                                    <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                                    </b-form-input>
                                </label>
                                </div>
                            </div>
                            <!-- End search -->
                            </div>
                            <div class="table-responsive">
                            <b-table :items="transactionData" :fields="fields" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                                :filter-included-fields="filterOn" @filtered="onFiltered">

                                <template v-slot:cell(action)="data">
                                <b-button-group>
                                    <b-button size="sm" v-b-modal.modal-1 variant="success" @click="Duzenle(data.item)">Düzenle</b-button>
                                    <b-button size="sm" variant="danger" @click="Sil(data.item)">Sil</b-button>
                                </b-button-group>
                                </template>
                            </b-table>
                            </div>
                            <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

                </b-col>
            </b-row>
            <b-modal id="modal-1" title="Dist Düzenle" v-if="modaldata!=null" hide-footer>
          <div class="row">
              <div class="col-md-12">
                  <b-form-group id="fieldset-1" label="Dist Adi" label-for="input-1">
                      <b-form-input id="input-1" size="sm" v-model="modaldata.adi" trim></b-form-input>
                  </b-form-group>
                  <b-form-group id="fieldset-1"  label-for="input-1">
                    <b-button variant="primary" @click="Guncelle">Güncelle</b-button>
                  </b-form-group>
              </div>
          </div>
      </b-modal>
                
        </div>
 </Layout>

</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { axiosInstance } from '../../../helpers/axios';
//import Swal from 'sweetalert2'
export default {
    name:"dist-islemleri",
    components: {
      Layout,
      PageHeader,

    },
    data() {
      return {
        title: "Dist İşlemleri",
        items: [{
            text: "Anasayfa"
          },
          {
            text: "Dist İşlemleri",
            active: true
          }
        ],
        distadi:"",
        transactionData: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 15,
        pageOptions: [5, 10, 15, 25, 50],
        filter: null,
        filterOn: [],
        sortBy: "orderid",
        sortDesc: false,
        fields: [{
            key: "action",
            label: "İşlemler"
          },{
            key: "adi",
            label: "Dist Adi"
          }
        ],
        modaldata:null
      }
    },
    created()
    {
        var  vm = this;
        vm.list();
        vm.totalRows = vm.transactionData.length;
    },
    computed: {
      rows() {
        return this.transactionData.length;
      },
    },
    methods:{
        onSubmit()
        {
            var vm = this;
            try {

                axiosInstance.post("dist/add",{
                    dist:vm.distadi,

                }).then((response)=>{
                    if(response.data.error==true)
                        {
                        vm.$bvToast.toast(response.data.message, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                        }else{
                        vm.$bvToast.toast("Dist Oluşturulmuştur", {
                            title: `Bildirim !`,
                            variant: "info",
                            solid: true
                        });
                        vm.distadi = "";
                        vm.list();
                        }
                }).catch((error)=>{
                    vm.$bvToast.toast(error, {
                            title: `Uyarı !`,
                            variant: "warning",
                            solid: true
                        });
                })
                
            } catch (error) {
               vm.$bvToast.toast(error, {
                            title: `Uyarı !`,
                            variant: "warning",
                            solid: true
                        });
            }
        },
        list()
        {
            var vm = this;
            vm.transactionData=[];
            try {

                axiosInstance.post("dist/list").then((response)=>{

                     if(response.data.error==true)
                        {
                        vm.$bvToast.toast(response.data.message, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                        }else{
                            response.data.data.forEach(element => {
                                vm.transactionData.push({
                                    adi: element.adi,
                                    id: element.id,
                                    action:""
                                });    
                            });
                        }

                }).catch((error)=>{

                    vm.$bvToast.toast(error, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                    
                })
                
            } catch (error) {

                vm.$bvToast.toast(error, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                
            }
        },
        Duzenle(params)
        {
            this.modaldata = params;
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
        },
        Guncelle()
        {
        var vm = this;
        try {
            axiosInstance.post("dist/update",{
              params:vm.modaldata
            }).then((response)=>{

              if(response.data.error==true)
              {
                vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                  title: `Uyari !`,
                  variant: "warning",
                  solid: true
                });
              }else{
                vm.$bvToast.toast("Güncelleme başarılı,Dist Güncellendi", {
                  title: `Bildirim !`,
                  variant: "info",
                  solid: true
                });
                vm.list();
              }
            
            
            }).catch(error=>{
              vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
            })

            
          } catch (error) {
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
          }

        },
        Sil(params1)
        {
            var vm = this;
        try {
            axiosInstance.post("dist/delete",{
              params:params1
            }).then((response)=>{

              if(response.data.error==true)
              {
                vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                  title: `Uyari !`,
                  variant: "warning",
                  solid: true
                });
              }else{
                vm.$bvToast.toast("Dist Silinmiştir", {
                  title: `Bildirim !`,
                  variant: "info",
                  solid: true
                });
                vm.list();
              }
            
            
            }).catch(error=>{
              vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
            })

            
          } catch (error) {
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
          }

        }

    }
}
</script>